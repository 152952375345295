import RunningIcon from 'assets/images/running.svg';
import BiddingIcon from 'assets/images/bidding.svg';
import OpenIcon from 'assets/images/open.svg';
import ClosedIcon from 'assets/images/closed.svg';
import ClosingIcon from 'assets/images/closing.svg';


import dotGreen from 'assets/images/dotGreen.svg';
import dotOrange from 'assets/images/dotOrange.svg';
import dotRed from 'assets/images/dotRed.svg';

export const { PI: Pi } = Math;
export const SHOWSIDENAV = 'showSideNav';
export const PAGE_DEFAULT = 1;
export const PAGE_SIZE = 10;
export const PAGE_SIZE_5 = 5;
export const PAGE_TOTAL = 20;
export const TAKE_ITEM_15 = 15;
export const TAKE_ITEM_30 = 30;

export const TAKE_ALL = 9999;
export const KEY_OF_MESSAGE = 'message';
export const STATUS_404 = 404;

export const FLAG_PROTECTED= 1;


export const MAX_LENGTH = 255;
/**
 * 20MD = 1MM
 */
export const MD_IN_MM = 20;
/**
 * 8h = 1MD
 */
export const HOUR_TO_MD = 8;

export const PERCENT_100 = 100;

// Datetime
// @deprecated using utils/helper.ts DATE_TIME_FORMAT
export const DATE = 'yyyy/MM/dd';
// @deprecated using utils/helper.ts DATE_TIME_FORMAT
export const DATE_VI_FORMAT = 'dd/MM/yyyy';
// @deprecated using utils/helper.ts DATE_TIME_FORMAT
export const DATE_SIMPLE = 'MM/dd';

// styles
export const MINW_ROADMAP_ITEMS = 242;
export const MINH_ROADMAP_ITEMS = 320;

export const DAYS_IN_WEEK = 5;
export const MAGIC_WIDTH = 160;
export const MAGIC_PROJECT_WIDTH = 220;
/**
 * mot ngay trong tuan
 */
export const MAGIC_CELL_WIDTH = MAGIC_WIDTH / DAYS_IN_WEEK;
// 5 is the number of columns in roadmap ~ 1 week = 5 days

export const MAGIC_PROJECT_CELL_WIDTH = MAGIC_PROJECT_WIDTH / DAYS_IN_WEEK;

export const ID_ELEMENT_START = 'roadmap-start';
export const ID_ELEMENT_TODAY = 'roadmap-today';
export const ID_ELEMENT_END = 'roadmap-end';
export const ID_ELEMENT_BOTTOM = 'roadmap-bottom';
export const ID_ELEMENT_SIDENAV = 'sideNav';
export const WIDTH_75VW = '75vw';
export const MAX_COMMITTED_VALUES = 9999.99;

export const NUMBER_MONTHS_GET_DATA = 5;

export const IS_DEV = process.env.NODE_ENV === 'development';
export const IS_PROD = process.env.NODE_ENV === 'production';

export const textConfirm = 'YES';
export const REGEX_NUMBER = /^\d*[0-9]\d*$/;

export const PARAMS_COMMON = {
  take: 9999,
  page: 1,
};

export const PARAMS_MEMBERS_BY_ROLE = 'role';
export const TEXT_EDITOR_EMPTY = '<p><br></p>';
export const TEXT_EDITOR_EMPTY_P = '<p></p>';
export const ALL_OPTIONS = 'all';

export const sortedRoles = [
  'PM',
  'COM',
  'BA',
  'DESIGN',
  'TEST-L',
  'TEST',
  'TEST-A',
  'TECH-L',
  'DEV-BE-L',
  'DEV-BE',
  'DEV-FE-L',
  'DEV-FE',
  'DEV-M-L',
  'DEV-M',
  'DEVOPS-L',
  'DEVOPS',
  'TL',
  'BRSE',
  'DL',
  'Sub DL',
  'BRSE-L',
];

export const PARAMS_MEMBERS = {
  store: 'masterdata'
};

export const PARAMS_PROJECT = {
  store: 'masterdata',
  take: 999
};

export enum ProjectStatus {
  Bidding = 'Bidding',
  Open = 'Open',
  Running = 'Running',
  Closing = 'Closing',
  Closed = 'Closed',
}

export enum CustomerStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum GroupBy {
  'month' = 'Group by month',
  'project' = 'Group by project',
}

export enum GroupByProjectSituation {
  'month' = 'Group by month',
  'all' = 'All',
}

export enum StatusProjectPayment {
  'All' = 'All project status',
  'WithoutBidding' = 'Without bidding',
}

export const COST_ITEM = [
  'Salary Cost',
  'Others Cost',
];

export const COST_ITEM_KEY = [
  'salary_cost',
  'other_cost',
];


export const PARAMS_STT_DEFAULT = `${ProjectStatus.Bidding},${ProjectStatus.Open},${ProjectStatus.Closing},${ProjectStatus.Running}`;
export const PARAMS_GROUP_BY = 'month';
export const PARAM_PROJECT_PAYMENT = 'all';
export const PARAM_DEFAULT_PROJECT_SITUATION = 'project';
export const PARAMS_ALL_DAY_OFF = {
  year: 'list'
};
export const PARAMS_EDIT_RA_BY_WEEK = 'week';

export enum UserRole {
  Admin = 'admin',
  Member = 'member',
  LOS = 'los',
}
export enum PaymentStatus {
  New = 'New',
  Processing = 'Processing',
  Paid = 'Paid',
  Cancelled = 'Cancelled',
}
export enum PaymentStatusNew {
  New = 'New',
}



export enum CostSalaryDivision {
  All = 'All division',
  Internal = 'Internal',
  Onsite = 'Onsite'
}

export enum CostPaymentType {
  CK = 'CK ngân hàng',
  TM = 'Tiền mặt',
}

export enum PaymentUnit {
  'mm' = 'MM',
  'amount' = 'VND',
}

export enum ProjectCurrency {
  JPY = 'JPY',
  USD = 'USD',
  VND = 'VND',
}

export enum CharactersCurrency {
  JPY = '¥',
  USD = '$',
  VND = '₫'
}
export interface Currency {
  value: 'VND' | 'USD' | 'JPY'
}

export interface Status {
  value: 'Bidding' | 'Open' | 'Running' | 'Closing' | 'Closed'
}

export enum Role {
  Admin = 'Admin',
  Member = 'Member',
  Los = 'LOS'
}

export enum conditions {
  Greater_than = '>',
  Greater_than_or_equal = '≥',
  Equal = '=',
  Less_than_or_equal = '≤',
  Less_than = '<',
}

export enum MemberStatus {
  'Active,Inactive' = 'Active & Inactive',
  'Active' = 'Active',
  'Inactive' = 'Inactive',
  'Deleted' = 'Deleted'
}

export const MemberStatusValue: any = {
  [MemberStatus.Active]: 'Active',
  [MemberStatus.Inactive]: 'Inactive',
  [MemberStatus.Deleted]: 'Deleted',
}

export enum MemberTypeLabel {
  'FullTimeC' = 'Fulltime-C',
  'FullTimeT' = 'Fulltime-T',
  'PartTime' = 'Part-time',
  'Intern' = 'Intern',
}

export const MemberTypeValue: any = {
  [MemberTypeLabel.FullTimeC]: 'FullTimeC',
  [MemberTypeLabel.FullTimeT]: 'FullTimeT',
  [MemberTypeLabel.PartTime]: 'PartTime',
  [MemberTypeLabel.Intern]: 'Intern',
};

export enum MemberStatusResourceSummaryLabel {
  All = 'All',
  Active = 'Active',
  Inactive = 'Inactive'
};

export const MemberStatusResourceSummaryValue: any = {
  [MemberStatusResourceSummaryLabel.All]: 'All',
  [MemberStatusResourceSummaryLabel.Active]: 'Active',
  [MemberStatusResourceSummaryLabel.Inactive]: 'Inactive',
};

export const ConditionValue: any = {
  [conditions.Greater_than]: '>',
  [conditions.Greater_than_or_equal]: '>=',
  [conditions.Equal]: '==',
  [conditions.Less_than_or_equal]: '<=',
  [conditions.Less_than]: '<',
};

export enum comparesLabel {
  All = 'All',
  AC = 'AC',
  TC_AC = 'AC + TC',
}


export const compareValue: any = {
  [comparesLabel.All]: 'All',
  [comparesLabel.AC]: 'AC',
  [comparesLabel.TC_AC]: 'AC,TC',
};

export enum percents {
  PERCENT__10 = '10%',
  PERCENT__20 = '20%',
  PERCENT__30 = '30%',
  PERCENT__40 = '40%',
  PERCENT__50 = '50%',
  PERCENT__60 = '60%',
  PERCENT__70 = '70%',
  PERCENT__80 = '80%',
  PERCENT__90 = '90%',
  PERCENT__100 = '100%'
}

export enum dayOfWeek {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}

export const percentValue: any = {
  [percents.PERCENT__10]: 10,
  [percents.PERCENT__20]: 20,
  [percents.PERCENT__30]: 30,
  [percents.PERCENT__40]: 40,
  [percents.PERCENT__50]: 50,
  [percents.PERCENT__60]: 60,
  [percents.PERCENT__70]: 70,
  [percents.PERCENT__80]: 80,
  [percents.PERCENT__90]: 90,
  [percents.PERCENT__100]: 100,
};


export const projectIcon = {
  [-1]: '',
  [ProjectStatus.Bidding]: BiddingIcon,
  [ProjectStatus.Open]: OpenIcon,
  [ProjectStatus.Running]: RunningIcon,
  [ProjectStatus.Closing]: ClosingIcon,
  [ProjectStatus.Closed]: ClosedIcon,
};

export const memberIcon = {
  [-1]: '',
  [MemberStatus.Active]: dotGreen,
  [MemberStatus.Inactive]: dotOrange,
  [MemberStatus.Deleted]: dotRed,
};

export const USER_ID = 'userId';
export const PROJECT_ID = 'projectId';
export const POSITION_ID = 'positionId';
export const PROJECT_RANK_ID = 'projectRankId';
export const PERIOD = 'period';
export const AC_PERCENT = 'acPercent';
export const TC_PERCENT = 'tcPercent';
export const TASK_ID = 'taskId';
export const ACTUAL_EFFORT = 'actualEffort';
export const NOTE = 'note';
export const CONFLICT = 'Conflict';
export const OVERTIME = 'OverTime';
export const ALL_STATUS = 'All Status';
export const ALL_LINE = 'All Lines';
export const NONE = 'none';
export const MONTH = 'month';
export const PROJECT = 'project';
export const EFFORT = 'mm';
export const AMOUNT = 'amount';


export const MAX_ACTUAL_EFFORT = 24;
export const MIN_ACTUAL_EFFORT = 0;

// Roadmap
export const HEIGHT_ROW = 34;
export const HEIGHT_ROW_HEADER = 40;
export const WIDTH_CELL = 180;

export const WIDTH_CELL_PROJECT = 180;

export enum ProjectTab {
  Information = '1',
  Logwork = '2',
  MemberEvaluation = '3',
  PaymentTracking = '4',
  CRTracking = '5',
}

export enum CostTab {
  Salary = '1',
  Other = '2',
}

export enum CostAction {
  Create = 'create',
  Update = 'update',
}

export enum WeekDays {
  MONDAY = 1,
  SATURDAY = 6,
  SUNDAY = 0,
}

export enum Page {
  ProjectMember = 'ProjectMember',
}

export const WIDTH_MENU_COLLAPSED = 80;
export const WIDTH_MENU = 205;

export enum MasterCode {
  ContractType = 'contract_type',
  DailyREportActivities = 'daily_report_activities',
  JobRank = 'job_rank',
  Line = 'line',
  ProjectRank = 'project_rank',
  ProjectRole = 'project_role',
  Department = 'department',
}

export enum Sort {
  ASC = 'ASC',
  DESC = 'DESC',
}

export const CUSTOM_OTHER_COST = '(Custom Other Cost)';