import { useQuery } from '@tanstack/react-query';
import { getUser } from 'api/userRole';

export const QK_DETAIL_USER = 'users';
export function useUser(params: any) {
  const res = useQuery([QK_DETAIL_USER, { params }], () => getUser(params), {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    enabled: Boolean(params)
  });
  return res;
}
