import { subMonths, compareDesc, compareAsc } from 'date-fns';
import addDays from 'date-fns/addDays';
import addMonths from 'date-fns/addMonths';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInMonths from 'date-fns/differenceInMonths';
import differenceInQuarters from 'date-fns/differenceInQuarters';
import endOfMonth from 'date-fns/endOfMonth';
import endOfQuarter from 'date-fns/endOfQuarter';
import endOfWeekFn from 'date-fns/endOfWeek';
import getDaysInMonth from 'date-fns/getDaysInMonth';
import getMonth from 'date-fns/getMonth';
import getQuarter from 'date-fns/getQuarter';
import getYear from 'date-fns/getYear';
import isBefore from 'date-fns/isBefore';
import startOfMonth from 'date-fns/startOfMonth';
import startOfQuarter from 'date-fns/startOfQuarter';
import startOfWeekFn from 'date-fns/startOfWeek';
import isMonday from 'date-fns/isMonday';
import isWeekend from 'date-fns/isWeekend';
import { RoadMapMode } from 'types/index.d';
import { ROUTES } from 'routes';
import { DAYS_IN_WEEK, MAGIC_CELL_WIDTH, MAGIC_WIDTH, sortedRoles } from './constant';
import { getCookie, removeCookie, setCookie } from './cookie';
import history from './history';

export const startOfWeek = (date: Date | number) => startOfWeekFn(date, { weekStartsOn: 1 });
export const endOfWeek = (date: Date | number) => endOfWeekFn(date, { weekStartsOn: 1 });

interface ITimelineParams {
  mode: RoadMapMode;
  timelineStart: Date;
  itemStart: Date;
  itemEnd: Date;
}

export function getDateRangesByMode(mode: RoadMapMode = RoadMapMode.Months, ranges: { start?: Date, end?: Date } = {}) {
  const now = new Date();
  const dates = [];
  let start = ranges?.start ?? subMonths(now, 2);
  let end = ranges?.end ?? addMonths(now, 3);

  switch (mode) {
    case RoadMapMode.Weeks: {
      start = startOfWeek(start);
      end = endOfWeek(end);
      for (let i = start; isBefore(i, end); i = addDays(i, 7)) {
        const _end = addDays(i, DAYS_IN_WEEK - 1);
        dates.push({ start: i, end: _end });
      }
      break;
    }
    case RoadMapMode.Months: {
      start = startOfMonth(start);
      end = endOfMonth(end);
      for (let i = start; isBefore(i, end); i = addMonths(i, 1)) {
        dates.push({ start: i, end: endOfMonth(i) });
      }
      break;
    }
    default:
      break;
  }
  if (!start || !end) return [];
  return dates;
}

export function getDates(start?: Date, end?: Date) {
  if (!start || !end) return [];
  const weeks = [];
  for (let i = start; isBefore(i, end); i = addDays(i, 1)) {
    weeks.push(i);
  }
  return weeks;
}

export function getMonths(start: Date, end: Date): number {
  if (getMonth(start) === getMonth(end) && getYear(start) === getYear(end)) {
    return (differenceInDays(end, start) + 1) / getDaysInMonth(start);
  }
  const endOfLastMonth = startOfMonth(end);
  const months = differenceInMonths(
    endOfLastMonth,
    endOfMonth(start),
  );
  const prefix = differenceInDays(endOfMonth(start), start) + 1;
  const suffix = differenceInDays(end, startOfMonth(end)) + 1;
  return prefix / getDaysInMonth(start) + months + suffix / getDaysInMonth(end);
}

export function getQuarters(start: Date, end: Date) {
  const quarters = differenceInQuarters(startOfQuarter(end), startOfQuarter(start));

  const endQuarter = getQuarter(end);
  const startOfNextQuarter = addDays(endOfQuarter(endQuarter), 1);
  const totalDays = differenceInDays(startOfNextQuarter, startOfQuarter(endQuarter));
  const daysFromStartOfQuarter = differenceInDays(addDays(end, 1), startOfQuarter(end));
  return quarters + daysFromStartOfQuarter / totalDays;
}

export function getStyleTimelineItem({
  mode,
  timelineStart,
  itemStart,
  itemEnd
}: ITimelineParams) {
  let left = 0;
  let width = 0;
  switch (mode) {
    case RoadMapMode.Weeks: {
      const daysFromStart = differenceInDays(
        itemStart,
        timelineStart,
      );
      const daysBetween = differenceInDays(
        itemEnd,
        addDays(itemStart, -1),
      );
      left = MAGIC_CELL_WIDTH * daysFromStart;
      width = MAGIC_CELL_WIDTH * daysBetween;
      break;
    }
    case RoadMapMode.Months: {
      const monthsFromStart = getMonths(timelineStart, itemStart);
      const monthsBetween = getMonths(itemStart, itemEnd);
      left = MAGIC_WIDTH * monthsFromStart - 8; // MAGIC_NUMBER
      width = MAGIC_WIDTH * monthsBetween;
      break;
    }
    case RoadMapMode.Quarters: {
      const quarterFromStart = getQuarters(timelineStart, itemStart);
      const quarterBetween = getQuarters(itemStart, itemEnd);
      left = MAGIC_WIDTH * quarterFromStart;
      width = MAGIC_WIDTH * quarterBetween;
      break;
    }
    default:
      break;
  }
  return { left, width };
}

export enum DATE_TIME_FORMAT {
  YYYY_MM = 'yyyy/MM',
  YYYY_MM_2 = 'yyyy-MM',
  YYYY_MM_DD = 'yyyy/MM/dd',
  YYYY_MM_DD_2 = 'yyyy-MM-dd',
  YYYY_MM_MM_HH_MM = 'yyyy/MM/dd HH:mm',
  YYYY_MM_MM_HH_MM_SS = 'yyyy/MM/DD HH:mm:ss',
  HH_MM_YYYY_MM_DD = 'hh:mm YYYY/MM/DD',
  TIME_DATE_24 = 'HH:mm YYYY/MM/DD',
  DD_MM_YYYY = 'dd/MM/yyyy',
  DD_MM = 'dd/MM',
  TIME = 'hh:mm',
  TIME2 = 'HH:mm',
  YYYYMMDD = 'yyyyMMdd',
  MM_DD = 'MM/dd',
  YY_MM_DD = 'yy/MM/dd',
  YYYYMM = 'yyyyMM',

}

export function scrollToCenter(id: string) {
  const todayElement = document.getElementById(id);
  if (todayElement) {
    todayElement.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    });
  }
}

export const hasOwn = Object.prototype.hasOwnProperty;

export const getFileName = (path: string) => {
  const index = path.lastIndexOf('/');
  return path.substring(index + 1);
};

interface IHandleLogin {
  accessToken: string;
  expiresOn: Date | null;
  callbackUrl?: string;
}
export const handleLogin = ({ accessToken, expiresOn, callbackUrl }: IHandleLogin) => {
  if (typeof window === 'undefined' || !accessToken) return;
  const expires = expiresOn ? +new Date(expiresOn) : 9999;
  setCookie('token', accessToken, {
    expires,
  });
  if (getCookie('token')) {
    history.push(callbackUrl ?? ROUTES.ResourcesProjects);
    window.location.reload();
  }
};

export const handleLogout = (callbackUrl = ROUTES.ResourcesProjects) => {
  removeCookie('token');
  removeCookie('refreshToken');
  localStorage.clear();
  if (callbackUrl) {
    history.push(callbackUrl);
  }
};

export const getPercent = (percent: number) => Math.round(percent);


export const getParamsWithoutFalsy = (params: any) => {
  const result: any = {};
  Object.keys(params).forEach((key) => {
    if (params[key]) {
      result[key] = params[key];
    }
  });
  return result;
};

export const filterOption = (input: string, option: any) =>
  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;


/**
 * Note: Keep this if isMonday or isWeekend
 * @param start
 * @param end
 * @returns
 */
export const formatRange = ({
  start,
  end,
  mode = RoadMapMode.Weeks
}: {
  start: string | Date;
  end: string | Date;
  mode?: RoadMapMode;
}) => {
  let startDate: Date;
  let endDate: Date;
  if (RoadMapMode.Months === mode) {
    return {
      startDate: typeof start === 'string' ? startOfMonth(new Date(start)) : startOfMonth(start),
      endDate: typeof end === 'string' ? endOfMonth(new Date(end)) : endOfMonth(end),
    };
  }
  if (typeof start === 'string' && typeof end === 'string') {
    const _start = startOfMonth(new Date(start));
    const _end = endOfMonth(new Date(end));
    startDate = isMonday(_start) ? _start : startOfWeek(_start);
    endDate = isWeekend(_end) ? _end : endOfWeek(_end);
  } else if (start instanceof Date && end instanceof Date) {
    const _start = startOfMonth(start);
    const _end = endOfMonth(end);
    startDate = isMonday(_start) ? _start : startOfWeek(_start);
    endDate = isWeekend(_end) ? _end : endOfWeek(_end);
  } else {
    // run when nothing from input
    startDate = startOfWeek(startOfMonth(subMonths(new Date(), 2)));
    endDate = endOfWeek(endOfMonth(addMonths(new Date(), 3)));
  }
  return {
    startDate,
    endDate,
  };
};

export const sortDescAlphabetByName = (a: any, b: any) => {
  const nameA = a?.username?.toUpperCase() ?? a?.name?.toUpperCase();
  const nameB = b?.username?.toUpperCase() ?? b?.name?.toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
};

export const sortAscAlphabetByName = (a: any, b: any) => {
  const nameA = a?.displayName?.toUpperCase() ?? a?.name?.toUpperCase();
  const nameB = b?.displayName?.toUpperCase() ?? b?.name?.toUpperCase();

  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
};

export const sortAscAlphabetByNameObject = (a: any, b: any) => {
  const nameA = a[1]?.displayName?.toUpperCase() ?? a?.name?.toUpperCase();
  const nameB = b[1]?.displayName?.toUpperCase() ?? b?.name?.toUpperCase();

  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
};

export const sortAscAlphabetByUsernameObject = (a: any, b: any) => {
  const nameA = a[1]?.username?.toUpperCase() ?? a?.username?.toUpperCase();
  const nameB = b[1]?.username?.toUpperCase() ?? b?.username?.toUpperCase();

  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
};

export const sortAscAlphabetByNameObjectSalary = (a: any, b: any) => {
  const nameA = a?.displayName?.toUpperCase() ?? '';
  const nameB = b?.displayName?.toUpperCase()  ?? '';
  return nameA.localeCompare(nameB)
};

export const sortRole = (a: any, b: any) => {
  const aIndex = sortedRoles.findIndex((ele: string) => ele === a?.code || ele === a?.positionCode);
  const bIndex = sortedRoles.findIndex((ele: string) => ele === b?.code || ele === b?.positionCode);
  if (aIndex > bIndex) {
    return 1;
  }
  if (aIndex < bIndex) {
    return -1;
  }
  return 0;
};

export const formaterAmountJPY = (value: number) => (
  new Intl.NumberFormat('ja-JP',
    { style: 'currency', currency: 'JPY' })
    .format(value)
);

export const formaterAmountVND = (value: number) => (
  new Intl.NumberFormat('ja-JP',
    { style: 'currency', currency: 'VND' })
    .format(value)
);

export const formaterAmountUSD = (value: number) => (
  new Intl.NumberFormat('en-US',
    { style: 'currency', currency: 'USD' })
    .format(value)
);

export const formaterAmountVND_2 = (value: number) => (
    `${new Intl.NumberFormat('ja-JP',
        { })
        .format(value)  } VND`
);

export const formaterCurrency = (value: number, currency: any) => {
  switch (currency) {
    case 'JPY':
      return new Intl.NumberFormat('ja-JP',
        { style: 'currency', currency: 'JPY' })
        .format(value);
    case 'VND':
      return new Intl.NumberFormat('ja-JP',
        { style: 'currency', currency: 'VND' })
        .format(value);
    case 'USD':
      return new Intl.NumberFormat('en-US',
        { style: 'currency', currency: 'USD' })
        .format(value);
    default:
      break;
  }
};

export const fixedNumber = (value: number, digits: number = 0) => {
  const factor = 10 ** digits;
  return (Math.round(value * factor) / factor).toFixed(digits);
};

export const formatMoney = (amount: any, decimalCount = 2, decimal = ".", thousands = ",") => {
  try {
    if(parseInt(amount) == parseFloat(amount)) {
      decimalCount = 0;
    }

    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount ? decimal + Math.abs(amount - parseFloat(i)).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
    console.log(e)
  }
};

export const fomaterAmount = (value: any) => String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
export const isValidEmail = (email: string) => /\S+@\S+\.\S+/.test(email);

export const sortDescStartDateProject = (a: any, b: any) => compareDesc(new Date(a?.startDate ?? a?.date), new Date(b?.startDate ?? b?.date));
export const sortAscStartDateProject = (a: any, b: any) => compareAsc(new Date(a?.startDate ?? a?.date), new Date(b?.startDate ?? b?.date));
export const sortAscUpdatedDateProject = (a: any, b: any) => compareAsc(new Date(a?.update ?? a?.date), new Date(b?.update ?? b?.date));
export const sortDescUpdatedDateProject = (a: any, b: any) => compareDesc(new Date(a?.update ?? a?.date), new Date(b?.update ?? b?.date));
export const sortAscDate = (a: any, b: any) => compareAsc(new Date(a?.date), new Date(b?.date));
export const sortDescCreatedAtSituationHistory = (a: any, b: any) => compareDesc(new Date(a?.createdAt), new Date(b?.createdAt));
export const sortDescUpdatedAtSituationHistory = (a: any, b: any) => compareDesc(new Date(a?.updatedAt), new Date(b?.updatedAt));

export const hasError = (errors: any, attrs?: string[]): boolean => {
  let isError = false;
  Object.keys(errors).forEach((key) => {
    if (errors[key]) {
      if (attrs) {
        attrs.forEach((attr) => {
          if (errors[key]?.[attr]) {
            isError = true;
          }
        });
      } else {
        isError = true;
      }
    }
  });
  return isError;
};

export const isFloat = (n: number) => {
  return Number(n) === n && n % 1 !== 0;
}

export const isInt = (n: number) => {
  return Number(n) === n && n % 1 === 0;
}