import React from 'react';
import Button from 'components/atoms/Button';
import { Result } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'routes';

export default function NotFound() {
  const { t } = useTranslation();
  return (
    <Result
      status="404"
      title="404"
      subTitle={t('common.pageNotFound')}
      extra={(
        <Link to={ROUTES.Home}>
          <Button type="primary">{t('common.backHome')}</Button>
        </Link>
      )}
    />
  );
}
