/**
 * Manage local storage
 */

export enum LocalStorageKeys {
  expandProject = 'expandProject',
  expandMember = 'expandMember',
  expandCost = 'expandCost',
  expandActualSalary = 'expandActualSalary',

  // resource Project search query /resources/projects?startDate=2022-12-01&endDate=2023-04-30&allocateGroupType=week
  resourceProjectSearchQuery = 'rs-project-qs',
  actualSalarySearchQuery = 'rs-actual-salary-qs',
  resourceMemberSearchQuery = 'rs-member-qs',
  managementCostSearchQuery = 'rs-cost-qs',
  dashboardSearchQuery = 'rs-dashboard-qs',
  situationSearchQuery = 'rs-situation-qs',
  memberSearchQuery = 'rs-members-qs',

  freeResource = 'show-free-member',
  freeActualSalary = 'show-free-actual-salary',
}

export const getLocalStorage = (key: string) => localStorage.getItem(key);

export const setLocalStorage = (key: string, value: string) => localStorage.setItem(key, value);

export const removeLocalStorage = (key: string) => localStorage.removeItem(key);

export const clearLocalStorage = () => localStorage.clear();
