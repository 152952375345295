import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { LocalStorageKeys, getLocalStorage, setLocalStorage } from 'utils/localStorage';

export interface CostStatus {
  allocates: {
    [key: string]: any;
  };
  activeItems: {
    [key: string]: boolean;
  };
  queryParams: string;
  showFree: boolean;
  selectedItem: {
    [key: string | number]: boolean;
  };
};

const initialState: CostStatus = {
  allocates: {},
  activeItems: getLocalStorage(LocalStorageKeys.expandCost) ? JSON.parse(getLocalStorage(LocalStorageKeys.expandCost) || '') : {},
  queryParams: getLocalStorage(LocalStorageKeys.managementCostSearchQuery) || '',
  showFree: false,
  selectedItem: {},
};

export const costSlice = createSlice({
  name: 'management/cost',
  initialState,
  reducers: {
    setManagementCost: (state, action: PayloadAction<{ id: number; allocates: any }>) => {
      if (action.payload.allocates) {
        return {
          ...state,
          allocates: {
            ...state.allocates,
            [action.payload.id]: action.payload.allocates,
          }
        };
      };
      return state;
    },
    setActiveManagementCost: (state, action: PayloadAction<{ id: number }>) => {
      const activeItems = { ...state.activeItems };
      activeItems[action.payload.id] = !activeItems[action.payload.id];
      setLocalStorage(LocalStorageKeys.expandCost, JSON.stringify(activeItems));
      return {
        ...state,
        activeItems
      };
    },
    resetActiveManagementCost: (state) => {
      setLocalStorage(LocalStorageKeys.expandCost, JSON.stringify({}));
      return {
        ...state,
        activeItems: {},
      };
    },
    setManagementCostQueryParams: (state, action: PayloadAction<string>) => {
      setLocalStorage(LocalStorageKeys.managementCostSearchQuery, action.payload);
      return {
        ...state,
        queryParams: action.payload,
      };
    },
    setShowFree: (state, action: PayloadAction<boolean>) => {
      setLocalStorage(LocalStorageKeys.freeResource, action.payload ? '1' : '0');
      return {
        ...state,
        showFree: action.payload,
      };
    }
  },
});

// Action creators are generated for each case reducer function
export const { setManagementCost, setActiveManagementCost, resetActiveManagementCost, setManagementCostQueryParams, setShowFree } = costSlice.actions;

export default costSlice.reducer;
