import React, { useEffect, useMemo } from 'react';
import dashboard from 'assets/images/dashboard.svg';
import resourceAllocation from 'assets/images/resourceAllocation.svg';
import project from 'assets/images/project.svg';
import list from 'assets/images/list.svg';
import member from 'assets/images/member.svg';
import { useTranslation } from 'react-i18next';
import type { IMenu } from 'types';
import loadable from 'utils/loadable';
import { Link, useLocation } from 'react-router-dom';
import { useSpring, animated } from '@react-spring/web';
import spring from 'utils/spring';

import { useAppSelector } from 'store';
import { ROUTES } from 'routes';
import { UserRole } from 'utils/constant';
import styles from './styles.module.scss';

const Header = loadable(() => import('components/molecules/Header'));
const SideNav = loadable(() => import('components/molecules/Sidebar'));
const Svg = loadable(() => import('components/atoms/Svg'));
const Icon = loadable(() => import('./Icon'));

interface Props {
  children?: React.ReactNode | React.ReactNode[];
}

function Auth(props: Props) {
  const { children = null } = props;
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const userData = useAppSelector((state) => state.azureInfo.userData);
  const qsProject = useAppSelector((state) => state.resourcesProjectApi.queryParams);
  const qsResourcesMember = useAppSelector((state) => state.resourcesMemberApi.queryParams);
  const qsActualSalary = useAppSelector((state) => state.managementActualSalaryApi.queryParams);
  const qsDashboard = useAppSelector((state) => state.dashboardApi.queryParams);
  const qsSituation = useAppSelector((state) => state.situationApi.queryParams);
  const qsCost = useAppSelector((state) => state.managementCostApi.queryParams);
  const qsMember = useAppSelector((state) => state.memberApi.queryParams);
  const userRole = userData?.role;
  const [srpingStyles, api] = useSpring(() => spring.FADED);

  const menuLeft: IMenu[] = useMemo(() => [
    {
      key: ROUTES.Home,
      label: t('sideNav.dashboard'),
      icon: <Svg src={dashboard} />,
      url: ROUTES.Home,
    },
    (userRole === UserRole.Admin || userRole === UserRole.LOS) && {
      key: `${ROUTES.DashBoard}?${qsDashboard}`,
      label: <Link to={ROUTES.DashBoard}>{t('sideNav.dashboard')}</Link>,
      icon: <Svg src={dashboard} />,
      url: ROUTES.DashBoard,
    },
    {
      key: ROUTES.PrefixResources,
      label: t('sideNav.resourceAllocation'),
      icon: <Svg src={resourceAllocation} />,
      url: ROUTES.ResourcesMembers,
      children: [
        (userRole === UserRole.Admin ||
          userRole === UserRole.LOS ||
          (userRole === UserRole.Member && userData?.project?.length > 0)) && {
          key: `${ROUTES.ResourcesProjects}?${qsProject}`,
          label: <Link to={ROUTES.ResourcesProjects}>{t('sideNav.projects')}</Link>,
          url: ROUTES.ResourcesProjects,
        },
        {
          key: `${ROUTES.ResourcesMembers}?${qsResourcesMember}`,
          label: <Link to={ROUTES.ResourcesMembers}>{t('sideNav.member')}</Link>,
          url: ROUTES.ResourcesMembers,
        },
        (userRole === UserRole.Admin || userRole === UserRole.LOS) && {
          key: ROUTES.ResourcesSummary,
          label: <Link to={ROUTES.ResourcesSummary}>{t('sideNav.summary')}</Link>,
          url: ROUTES.ResourcesSummary,
        },
      ],
    },
    {
      key: ROUTES.PrefixProjects,
      label: t('sideNav.projects'),
      icon: <Svg src={project} />,
      url: ROUTES.Projects,
      children: [
        {
          key: ROUTES.Projects,
          label: <Link to={ROUTES.Projects}>{t('sideNav.projectList')}</Link>,
          url: ROUTES.Projects,
        },
        (userRole === UserRole.Admin || userRole === UserRole.LOS) &&{
          key: ROUTES.ProjectPayments,
          label: <Link to={ROUTES.ProjectPayments}>{t('sideNav.payments')}</Link>,
          url: ROUTES.ProjectPayments,
        },
        (userRole === UserRole.Admin || userRole === UserRole.LOS) && {
          key: `${ROUTES.ProjectSituations}?${qsSituation}`,
          label: <Link to={ROUTES.ProjectSituations}>{t('sideNav.situation')}</Link>,
          url: ROUTES.ProjectSituations,
        },
        (userRole === UserRole.Admin || userRole === UserRole.LOS) && {
          key: ROUTES.Customer,
          label: <Link to={ROUTES.Customer}>{t('sideNav.customer')}</Link>,
          url: ROUTES.Customer,
        },
      ]
    },
    (userRole === UserRole.Admin) &&  {
      key: ROUTES.PrefixManagement,
      label: t('sideNav.management'),
      icon: <Svg src={list} />,
      url: ROUTES.Costs,
      children: [
        {
          key: `${ROUTES.Costs}?${qsCost}`,
          label: <Link to={ROUTES.Costs}>{t('sideNav.costs')}</Link>,
          url: ROUTES.Costs,
        },
        {
          key: `${ROUTES.ActualSalary}?${qsActualSalary}`,
          label: <Link to={ROUTES.ActualSalary}>{t('sideNav.actualSalary')}</Link>,
          url: ROUTES.ActualSalary,
        },
      ]
    },
    (userRole === UserRole.Admin) && {
      key: `${ROUTES.Members}?${qsMember}`,
      label: <Link to={ROUTES.Members}>{t('sideNav.member')}</Link>,
      icon: <Svg src={member} />,
      url: ROUTES.Members,
    }
    ,
  ], [qsResourcesMember, qsProject, t, userRole, qsActualSalary, qsDashboard, qsSituation, qsCost, qsMember]);

  useEffect(() => {
    api.start(spring.FADED);
    return () => {
      api.stop();
    };
  }, [api, pathname]);

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.mainWrapper}>
        <Header />
        <div className={styles.coverContent}>
          <SideNav menus={menuLeft} />
          <animated.div style={srpingStyles} className={styles.pageContent} >
            {children}
          </animated.div>
        </div>
      </div>
    </div>
  );
}

export default Auth;
