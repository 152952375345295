import { createSlice, PayloadAction } from '@reduxjs/toolkit';



export interface StatusProjectState {
  statusFilter: string[];
  statusProjectSituations: string;
};


const initialStateProject: StatusProjectState = {
  statusFilter: ['Running'],
  statusProjectSituations: 'Running'
};

export const projectSlice = createSlice({
  name: 'Project Slice',
  initialState: initialStateProject,
  reducers: {
    setFilterStatusProject: (state, action: PayloadAction<string[]>) => {
      const statusFilter =  action.payload;
      return {
        ...state,
        statusFilter
      };
    },
    setFilterStatusProjectSituations: (state, action: PayloadAction<string>) => {
      const status = action.payload;
      return {
        ...state,
        statusProjectSituations: status
      };
    }
  }
});

export const { setFilterStatusProject, setFilterStatusProjectSituations } = projectSlice.actions;

export default projectSlice.reducer;
