// eslint-disable-next-line import/extensions
// import './wdyr'; // <--- first import

import React from 'react';
import { MsalProvider } from '@azure/msal-react';
import { Provider } from 'react-redux';
import { store } from 'store';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { msalInstance } from 'utils/azureConfig';
import { GoogleOAuthProvider } from '@react-oauth/google';


import ReactDOM from 'react-dom/client';
import history from 'utils/history';
import configs from 'utils/configs';
import App from './App';

import './utils/i18n';
import 'antd/dist/antd.min.css';
import 'react-quill/dist/quill.snow.css';

import 'styles/index.scss';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
  <GoogleOAuthProvider clientId={configs.GOOGLE_CLIENT_ID}>
      <HistoryRouter history={history}>
        <Provider store={store}>
          <App />
        </Provider>
      </HistoryRouter>
      </GoogleOAuthProvider>
  </React.StrictMode >
);
