import { ReactNode } from "react";

export interface Theme {
  primary: string;
  secondary: string;
  accent: string;
  neutral: string;
  'base-100': string;
  info: string;
  success: string;
  warning: string;
  error: string;
}

export interface ShowFilter {
  modalPage: string;
  project: boolean;
  range: boolean;
  member: boolean;
  line: boolean;
  positions: boolean;
  status: boolean;
  freeResource: boolean;
  division?: boolean;
}

export interface TotalPayment {
  effortPaid: number;
  effortPending: number;
  effortCancelled: number;
  amountPending: number;
  amountReceived: number;
  amountPendingJpy: number;
  amountPendingVnd: number;
  amountPendingUsd: number;
  amountReceivedJpy: number;
  amountReceivedVnd: number;
  amountReceivedUsd: number;
}

export type AzureInfoState = {
  "@odata.context": string;
  "businessPhones"?: any[];
  "displayName"?: string;
  "givenName"?: string;
  "jobTitle"?: string,
  "picture"?: string,
  "mail"?: string;
  "mobilePhone"?: string;
  "officeLocation"?: string;
  "preferredLanguage"?: string;
  "surname"?: string;
  "userPrincipalName"?: string;
  "id": "6a662e0a-11a3-4011-b5ca-73c4fe8346be"
}

export type IMenu = Required<MenuProps>['items'][number];

export enum RoadMapMode {
  Days = 'day',
  Weeks = 'week',
  Months = 'month',
  Quarters = 'quarters',
}

export interface IParamPopover {
  projectId?: number;
  userId?: string;
  positionId?: number;
}
export interface IParam {
  take?: number,
  page?: number,
}

export interface IResourceMember {
  status?: string;
  key?: number,
  projectId?: number,
  projectRankId?: number,
  userId?: string,
  positionId?: string,
  period?: any,
  acPercent?: number,
  tcPercent?: number,
  note?: string,
  startDate?: any,
  endDate?: any;
  note?: string;
}

export interface ILogwork {
  id?: number;
  name?: string,
  reportDate?: any;
  activity?: string;
  taskId?: string;
  actualEffort?: string;
  note?: string;
  projectId?: number,
}

interface IProject {
  id?: number;
  name?: string;
  pm?: string;
  actual?: string;
  code?: number | string;
  type?: string;
  status?: any;
  startDate?: string;
  endDate?: string;
  startDateActual?: string;
  endDateActual?: string;
  customerId?: number;
  billable?: number;
  budget?: number;
  currency?: any;
  internalPrice?: number;
  externalPrice?: number;
  contractType?: string;
  createdAt?: string;
  updatedAt?: string;
}
interface IPayment {
  title: string;
  effort: number;
  amount: number;
  paydate?: any;
  status: string;
  note?: string;
  projectId?: number;
}

interface IPosition {
  id?: number,
  createdAt?: string,
  updatedAt?: string,
  name?: string,
}

export interface ISituation {
  id?: number;
  projectId?: number;
  note?: string;
  submitterId?: string;
  take?: number;
  page?: number;
  oder?: string;
}
interface IMember {
  id?: string,
  createdAt?: string,
  updatedAt?: string,
  startDate?: string,
  endDate?: string,
  displayName?: string,
  userName?: string,
  note?: string,
  phone?: string,
  time?: string,
  total?: number
}
export interface IProjectInfo {
  [id: string]: {
    id: number;
    name?: string;
    status: 'Bidding' | 'Open' | 'Running' | 'Closing' | 'Closed';
    pm?: string;
    positions?: any;
  }
}

export interface IResource {
  [x: string]: any;
  id: number;
  name: string;
  status?: 'Bidding' | 'Open' | 'Running' | 'Closing' | 'Closed';
  positions?: {
    id: number;
    name: string;
    code: string;
    actualEffort?: number;
    resources?: {
      allocated: number;
      committed?: number;
      ac: number;
      tc: number;
      startDate: string;
      endDate: string;
    }[];
  }[];
  allocates?: IResource[];
}
export interface IMemberResource {
  [id: string]: {
    line?: string;
    mail?: string;
    name?: string;
    role?: string;
    userName?: string;
    [date: string]: {
      totalAcPercent?: number;
      totalTcPercent?: number;
      projects?: IProjectInfo;
    }
    projectInfo: IProjectInfo;
  }
}
export interface IParamResource {
  edit?: string;
  userId?: string;
  projectIds?: number;
  resources?: IResourceMember[];
}

export interface IVerifyResource {
  verifyToken?: string;
  edit?: string;
}

export interface IFilter {
  mode?: RoadMapMode;
  startDate: string;
  endDate: string;
  member?: Array<string>;
  line?: Array<string>;
  lineIds?: Array<string>;
  positionIds?: Array<string>;
  divisionIds?: Array<string>;
  project?: Array<string>;
  status?: Array<string>;
  userId?: string;
}

export interface IFilterCost {
  startDate: string;
  endDate: string;
  departmentIds?:Array<string>;
}

export interface IFilterDashboard {
  startDate: string;
  endDate: string;
  groupBy?:string;
  unitPayment?:string;
  statusProject?:string;
}

export interface IFilterSituation {
  startDate: string;
  endDate: string;
  groupBy?:string;
  status?:string;
  projectIds?:string;
}

export interface IParamDetailResource {
  projectId?: number | null;
  userId?: string;
}

export interface IParamDetailProject {
  projectId?: number | null;
}

export interface IUser {
  id?: string,
  mail?: string,
  displayName?: string
}

export interface IResourceMemberDetail {
  id?: number,
  createdAt?: string,
  updatedAt?: string,
  userProjectId?: number,
  date?: string | date,
  acPercent?: number,
  tcPercent?: number,
  startDate?: string | date,
  endDate?: string | date,
  positionId?: number,
  projectId?: string,
  userId?: string,
  period?: any,
  key?: number,
  note?: string,
}

export interface IDetailResource {
  id?: number;
  users?: IUser;
  resources?: IResourceMemberDetail[];
}

export interface IOption {
  value?: string | number;
  label?: string;
}

export interface IPageSizeParams {
  take?: number;
  page?: number;
}

export interface IJobRankItem {
  id?: number | string;
  name?: string;
  order?: number;
}

export interface MetaPageInfo {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  itemCount: number;
  page: number;
  pageCount: number
  take: number;
}
