
import { useQuery } from '@tanstack/react-query';
import { getMemberByProject } from 'api/memberByProject';

export const QK_MEMBER_BY_PROJECT = 'members';

export function useMemberByProject(params?: any, userId?: string) {
  const res = useQuery([QK_MEMBER_BY_PROJECT, { params }], () => getMemberByProject(params), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: !!params || !!userId
  });
  return res;
}
