import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

export interface RefetchApiState {
  stateRfInfoProject: boolean;
  stateRfProject: boolean;
  stateRfResourceSummaryProject: boolean;
}

export const RefetchApiSlice = createSlice<RefetchApiState, SliceCaseReducers<RefetchApiState>>({
  name: 'RefetchApiSlice',
  initialState: {
    stateRfInfoProject: false,
    stateRfProject: false,
    stateRfResourceSummaryProject: false,
  },
  reducers: {
    setStateRefetchInfoProject: (state: RefetchApiState, action: PayloadAction<boolean>) =>{
      const stateRfInfoProject = action.payload;
      return {
        ...state,
        stateRfInfoProject
      };
    },
    setStateRefetchResourceSummaryProject: (state: RefetchApiState, action: PayloadAction<boolean>) =>{
      const stateRfResourceSummaryProject = action.payload;
      return {
        ...state,
        stateRfResourceSummaryProject
      };
    },
    setStateRefetchProject: (state: RefetchApiState, action: PayloadAction<boolean>) =>{
      const stateRfProject = action.payload;
      return {
        ...state,
        stateRfProject
      };
    }
  }
});

export const {
  setStateRefetchInfoProject,
  setStateRefetchResourceSummaryProject,
  setStateRefetchProject
  } = RefetchApiSlice.actions;

export default RefetchApiSlice.reducer;
