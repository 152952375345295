import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { LocalStorageKeys, getLocalStorage, setLocalStorage } from 'utils/localStorage';

export interface memberStatus {
 
  queryParams: string;
};

const initialState: memberStatus = {
  queryParams: getLocalStorage(LocalStorageKeys.memberSearchQuery) || '',
};

export const memberSlice = createSlice({
  name: 'members',
  initialState,
  reducers: {
   
    setMemberQueryParams: (state, action: PayloadAction<string>) => {
      setLocalStorage(LocalStorageKeys.memberSearchQuery, action.payload);
      return {
        ...state,
        queryParams: action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setMemberQueryParams } = memberSlice.actions;

export default memberSlice.reducer;
