import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { LocalStorageKeys, getLocalStorage, setLocalStorage } from 'utils/localStorage';

export interface ResourcesProjectStatus {
  allocates: {
    [key: string]: any;
  };
  activeItems: {
    [key: string]: boolean;
  };
  queryParams: string;
  dataAllocation: any[];
};

const initialState: ResourcesProjectStatus = {
  allocates: {},
  activeItems: getLocalStorage(LocalStorageKeys.expandProject) ? JSON.parse(getLocalStorage(LocalStorageKeys.expandProject) || '') : {},
  queryParams: getLocalStorage(LocalStorageKeys.resourceProjectSearchQuery) || '',
  dataAllocation: [],
};

export const resourcesProjectSlice = createSlice({
  name: 'resources/project',
  initialState,
  reducers: {
    setResourceProject: (state, action: PayloadAction<{ id: number; allocates: any[] }>) => {
      if (Array.isArray(action.payload.allocates)) {
        return {
          ...state,
          allocates: {
            ...state.allocates,
            [action.payload.id]: action.payload.allocates,
          }
        };
      };
      return state;
    },
    setActiveResourceProject: (state, action: PayloadAction<{ id: number }>) => {
      const activeItems = { ...state.activeItems };
      activeItems[action.payload.id] = !activeItems[action.payload.id];
      setLocalStorage(LocalStorageKeys.expandProject, JSON.stringify(activeItems));
      return {
        ...state,
        activeItems
      };
    },
    setResourceProjectQueryParams: (state, action: PayloadAction<string>) => {
      setLocalStorage(LocalStorageKeys.resourceProjectSearchQuery, action.payload);
      return {
        ...state,
        queryParams: action.payload,
      };
    },
    setDataAllocations: (state, action: PayloadAction<any[]>) => ({
      ...state,
      dataAllocation: action.payload,
    }),
  },
});

// Action creators are generated for each case reducer function
export const { setResourceProject, setActiveResourceProject, setResourceProjectQueryParams, setDataAllocations } = resourcesProjectSlice.actions;

export default resourcesProjectSlice.reducer;
