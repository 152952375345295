import { message } from 'antd';

message.config({ maxCount: 1 });

export default {
  BASE_URL: import.meta.env.VITE_BASE_URL,
  ENV: import.meta.env.VITE_ENV,
  CLIENT_ID_MICROSOFT: import.meta.env.VITE_CLIENT_ID_MICROSOFT,
  REDIRECT_URL_MICROSOFT: import.meta.env.VITE_REDIRECT_URL_MICROSOFT,
  GOOGLE_CLIENT_ID: import.meta.env.VITE_GOOGLE_CLIENT_ID,
  GOOGLE_CLIENT_SECRET: import.meta.env.VITE_GOOGLE_CLIENT_SECRET,
  GOOGLE_REDIRECT_URI: import.meta.env.VITE_GOOGLE_REDIRECT_URI,
  AUTHORITY: import.meta.env.VITE_AUTHORITY,
  SCOPE: import.meta.env.VITE_SCOPE,
  URL_GRAPH_MICROSOFT: import.meta.env.VITE_URL_GRAPH_MICROSOFT,
  SCOPE_API: import.meta.env.VITE_SCOPE_API
};