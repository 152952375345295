import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { LocalStorageKeys, getLocalStorage, setLocalStorage } from 'utils/localStorage';

export interface ResourcesProjectStatus {
  allocates: {
    [key: string]: any;
  };
  activeItems: {
    [key: string]: boolean;
  };
  queryParams: string;
  showFree: boolean;
};

const initialState: ResourcesProjectStatus = {
  allocates: {},
  activeItems: getLocalStorage(LocalStorageKeys.expandMember) ? JSON.parse(getLocalStorage(LocalStorageKeys.expandMember) || '') : {},
  queryParams: getLocalStorage(LocalStorageKeys.resourceMemberSearchQuery) || '',
  showFree: false,
};

export const resourcesMemberSlice = createSlice({
  name: 'resources/project',
  initialState,
  reducers: {
    setResourceMember: (state, action: PayloadAction<{ id: number; allocates: any }>) => {
      if (action.payload.allocates) {
        return {
          ...state,
          allocates: {
            ...state.allocates,
            [action.payload.id]: action.payload.allocates,
          }
        };
      };
      return state;
    },
    setActiveResourceMember: (state, action: PayloadAction<{ id: number }>) => {
      const activeItems = { ...state.activeItems };
      activeItems[action.payload.id] = !activeItems[action.payload.id];
      setLocalStorage(LocalStorageKeys.expandMember, JSON.stringify(activeItems));
      return {
        ...state,
        activeItems
      };
    },
    resetActiveResourceMember: (state) => {
      setLocalStorage(LocalStorageKeys.expandMember, JSON.stringify({}));
      return {
        ...state,
        activeItems: {},
      };
    },
    setResourceMemberQueryParams: (state, action: PayloadAction<string>) => {
      setLocalStorage(LocalStorageKeys.resourceMemberSearchQuery, action.payload);
      return {
        ...state,
        queryParams: action.payload,
      };
    },
    setShowFree: (state, action: PayloadAction<boolean>) => {
      setLocalStorage(LocalStorageKeys.freeResource, action.payload ? '1' : '0');
      return {
        ...state,
        showFree: action.payload,
      };
    }
  },
});

// Action creators are generated for each case reducer function
export const { setResourceMember, setActiveResourceMember, resetActiveResourceMember, setResourceMemberQueryParams, setShowFree } = resourcesMemberSlice.actions;

export default resourcesMemberSlice.reducer;
