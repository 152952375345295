import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { LocalStorageKeys, getLocalStorage, setLocalStorage } from 'utils/localStorage';

export interface CostStatus {
  allocates: {
    [key: string]: any;
  };
  activeItems: {
    [key: string]: boolean;
  };
  queryParams: string;
  showFree: boolean;
  selectedItem: {
    [key: string | number]: boolean;
  };
};

const initialState: CostStatus = {
  allocates: {},
  activeItems: getLocalStorage(LocalStorageKeys.expandCost) ? JSON.parse(getLocalStorage(LocalStorageKeys.expandCost) || '') : {},
  queryParams: getLocalStorage(LocalStorageKeys.actualSalarySearchQuery) || '',
  showFree: false,
  selectedItem: {},
};

export const actualSalarySlice = createSlice({
  name: 'management/actualSalary',
  initialState,
  reducers: {
    setActualSalary: (state, action: PayloadAction<{ id: number; allocates: any }>) => {
      if (action.payload.allocates) {
        return {
          ...state,
          allocates: {
            ...state.allocates,
            [action.payload.id]: action.payload.allocates,
          }
        };
      };
      return state;
    },
    setActiveActualSalary: (state, action: PayloadAction<{ id: string }>) => {
      const activeItems = { ...state.activeItems };
      activeItems[action.payload.id] = !activeItems[action.payload.id];
      setLocalStorage(LocalStorageKeys.expandActualSalary, JSON.stringify(activeItems));
      return {
        ...state,
        activeItems
      };
    },
    resetActiveActualSalary: (state) => {
      setLocalStorage(LocalStorageKeys.expandActualSalary, JSON.stringify({}));
      return {
        ...state,
        activeItems: {},
      };
    },
    setActualSalaryQueryParams: (state, action: PayloadAction<string>) => {
      setLocalStorage(LocalStorageKeys.actualSalarySearchQuery, action.payload);
      return {
        ...state,
        queryParams: action.payload,
      };
    },
    setShowFree: (state, action: PayloadAction<boolean>) => {
      setLocalStorage(LocalStorageKeys.freeActualSalary, action.payload ? '1' : '0');
      return {
        ...state,
        showFree: action.payload,
      };
    },
    setIsLoading: (state, action: PayloadAction<{ id: number | string; isLoading: boolean }>) => {
      const { id, isLoading } = action.payload;
      return {
        ...state,
        isLoading,
        selectedItem: {
          ...state.selectedItem,
          [id]: isLoading,
        }
      };
    }
  },
});

// Action creators are generated for each case reducer function
export const { setActualSalary, setActiveActualSalary, resetActiveActualSalary, setActualSalaryQueryParams, setShowFree, setIsLoading } = actualSalarySlice.actions;

export default actualSalarySlice.reducer;
