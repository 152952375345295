import { useDispatch, useSelector } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import azureInfoReducer from './azureInfo';
import refetchApiReducer from './stateRefetchApi';
import resourcesApiReducer from './resources/index';
import resourcesProjectReducer from './resources/project';
import resourcesMemberReducer from './resources/member';
import filterStatusProjectReducer from './projects/index';
import dashboardReducer from './dashboard/dashboard';
import situationReducer from './projects/situation';
import managementCostReducer from './management/cost';
import managementActualSalaryReducer from './management/actualSalary';
import memberReducer from './members';

// ...

export const store = configureStore({
  reducer: {
    azureInfo: azureInfoReducer,
    refetchApi: refetchApiReducer,
    resourcesApi: resourcesApiReducer,
    resourcesProjectApi: resourcesProjectReducer,
    resourcesMemberApi: resourcesMemberReducer,
    filterStatusProject: filterStatusProjectReducer,
    dashboardApi: dashboardReducer,
    situationApi: situationReducer,
    managementCostApi: managementCostReducer,
    managementActualSalaryApi: managementActualSalaryReducer,
    memberApi: memberReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
