import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface ResourceState {
  selectedItem: {
    [key: string | number]: boolean;
  };
  isLoading: boolean;
};

const initialState: ResourceState = {
  selectedItem: {},
  isLoading: false,
};

export const resourcesSlice = createSlice({
  name: 'resources/index',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<{ id: number | string; isLoading: boolean }>) => {
      const { id, isLoading } = action.payload;
      return {
        ...state,
        isLoading,
        selectedItem: {
          ...state.selectedItem,
          [id]: isLoading,
        }
      };
    }
  },
});

// Action creators are generated for each case reducer function
export const { setIsLoading } = resourcesSlice.actions;

export default resourcesSlice.reducer;
