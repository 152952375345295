import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { AzureInfoState } from 'types/index.d';

export const initialStateValue = '';

export interface AzureSliceState {
  userInfo: AzureInfoState | undefined | null;
  menuCollapsed: boolean;
  userData: any;
  isExpanded: boolean;
  refetchPopover: boolean;
  userProject: any;
}

export const azureSlice = createSlice<AzureSliceState, SliceCaseReducers<AzureSliceState>>({
  name: 'azureSlice',
  initialState: {
    userData: undefined,
    userInfo: undefined,
    userProject: undefined,
    menuCollapsed: localStorage.getItem('menuCollapsed') === '1',
    isExpanded: true,
    refetchPopover: false,
  },
  reducers: {
    setUserInfo: (state: AzureSliceState, action: PayloadAction<AzureInfoState>) => {
      const userInfo = action.payload;
      return {
        ...state,
        userInfo,
      };
    },
    setMenuCollapsed: (state: AzureSliceState, action: PayloadAction<boolean>) => {
      const collapsed = action.payload ? 1 : 0;
      localStorage.setItem('menuCollapsed', `${collapsed}`);
      return {
        ...state,
        menuCollapsed: action.payload,
      };
    },
    setUserData: (state: AzureSliceState, action: PayloadAction<AzureSliceState>) => {
      const userData = action.payload;
      return {
        ...state,
        userData,
      };
    },
    setUserProject: (state: AzureSliceState, action: PayloadAction<AzureSliceState>) => {
      const userProject = action.payload;
      return {
        ...state,
        userProject,
      };
    },
    setExpanded: (state: AzureSliceState, action: PayloadAction<boolean>) => {
      const isExpanded = action.payload;
      return{
        ...state,
        isExpanded,
      };
    },
    setStateRefetchPopover: (state: AzureSliceState, action: PayloadAction<boolean>) => {
      const stateRefetch = action.payload;
      return {
        ...state,
        refetchPopover: stateRefetch,
      };
    }
  },
});

export const { setUserInfo, setMenuCollapsed, setUserData, setExpanded, setStateRefetchPopover, setUserProject } = azureSlice.actions;

export default azureSlice.reducer;
