export const FADED = { // faded
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
  config: {
    duration: 500,
  },
};

export default {
  FADED,
};
