import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { LocalStorageKeys, getLocalStorage, setLocalStorage } from 'utils/localStorage';

export interface DashboardStatus {
  queryParams: string;
};

const initialState: DashboardStatus = {
  queryParams: getLocalStorage(LocalStorageKeys.dashboardSearchQuery) || '',
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDashboardyQueryParams: (state, action: PayloadAction<string>) => {
      setLocalStorage(LocalStorageKeys.dashboardSearchQuery, action.payload);
      return {
        ...state,
        queryParams: action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setDashboardyQueryParams } = dashboardSlice.actions;

export default dashboardSlice.reducer;
