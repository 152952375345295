import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { LocalStorageKeys, getLocalStorage, setLocalStorage } from 'utils/localStorage';

export interface SituationStatus {
  queryParams: string;
};

const initialState: SituationStatus = {
  queryParams: getLocalStorage(LocalStorageKeys.situationSearchQuery) || '',
};

export const situationSlice = createSlice({
  name: 'situation',
  initialState,
  reducers: {
    setSituationQueryParams: (state, action: PayloadAction<string>) => {
      setLocalStorage(LocalStorageKeys.situationSearchQuery, action.payload);
      return {
        ...state,
        queryParams: action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSituationQueryParams } = situationSlice.actions;

export default situationSlice.reducer;
