import React, { useEffect } from 'react';
import Spin from 'components/atoms/Spin';
import { getCookie } from 'utils/cookie';
import { handleLogin, handleLogout } from 'utils/helper';
import { useSearchParams } from 'react-router-dom';
import configs from 'utils/configs';
import logging from 'utils/logging';
import { ROUTES } from 'routes';
import axios from 'axios';

function RefreshToken() {
  const [searchParams] = useSearchParams();
  const callbackUrl = searchParams.get('callbackUrl') ?? '/';
  const refreshToken  = getCookie('refreshToken');
  const token  = getCookie('token');

  const refreshTokenLogin = async () => {
    let flagToken = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', { 
        headers : { Authorization : `Bearer ${token}` } 
      }).then((response) => {
        return true;
      }).catch(error => {
        return false;
      });

    if(!flagToken) {
      axios.post('https://oauth2.googleapis.com/token', {
        client_id: configs.GOOGLE_CLIENT_ID,
        client_secret: configs.GOOGLE_CLIENT_SECRET,
        refresh_token: refreshToken,
        grant_type: 'refresh_token',
      }).then((response) => {
        const now = new Date();
        handleLogin({
          accessToken : response.data.access_token,
          expiresOn : new Date(now.getTime() + response.data.expires_in),
          callbackUrl
        });
      }).catch((error) => {
        handleLogout(ROUTES.Login);
        logging.error(error);
      });
    }
  }
  
  useEffect(() => {
    if(refreshToken) {
      refreshTokenLogin();
    } else {
      handleLogout(ROUTES.Login);
    }
  }, []);

  return (
    <>
      <Spin />
    </>
  );
}


export default RefreshToken;
