import React, { useEffect, useMemo, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { getCookie } from 'utils/cookie';
import { useAppDispatch } from 'store';
import { setUserData, setUserInfo, setUserProject } from 'store/azureInfo';
import configs from 'utils/configs';
import routes from 'routes';
import NotFound from 'pages/404';
import Blank from 'layouts';
import logging from 'utils/logging';
import { useUser } from 'hooks/useUser';
import { useMemberByProject } from 'hooks/useMemberByProject';
import axios from 'axios';

function Wrapper() {
  const token = getCookie('token');
  const dispatch = useAppDispatch();

  const [userId, setUserID] = useState();
  const { data: user } = useUser(userId);
  const { data: dataMemberInProject } = useMemberByProject(null, userId);
  const listIdProjects = useMemo(() => dataMemberInProject?.map((item: any) => item.id), [dataMemberInProject]);

  useEffect(() => {
    if (user) {
      dispatch(setUserData(user));
    }
    if (listIdProjects) {
      dispatch(setUserProject(listIdProjects));
    }
  }, [user, listIdProjects]);

  useEffect(() => {
    if (token) {
      axios.get('https://www.googleapis.com/oauth2/v3/userinfo',
      { headers : { Authorization : `Bearer ${token}` } }
      ).then((response) => {
        if (response.status === 200) {
          dispatch(setUserInfo(response.data));
          setUserID(response.data.sub);
        }
      }).catch(error => logging.error(error));
    }
  }, [dispatch]);

  return (
    <Routes>
      {routes.map((route, index) => {
        const Layout = route.layout ?? React.Fragment;
        if (route.isAuth) {
          return (
            <Route
              key={index}
              path={route.path}
              element={!token
                ? <Navigate replace to="/login" />
                : (
                  <React.Fragment>
                    <Blank>
                      <Layout>
                        <route.component />
                      </Layout>
                    </Blank>
                  </React.Fragment>
                )}
            />
          );
        }
        return (
          <Route
            key={index}
            path={route.path}
            element={(
              <React.Fragment>
                <Blank>
                  <Layout>
                    <route.component />
                  </Layout>
                </Blank>
              </React.Fragment>
            )}
          />
        );
      })}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default Wrapper;
