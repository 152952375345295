import RefreshToken from 'pages/refresh-token';
import loadable from 'utils/loadable';
import Auth from 'wrapper/Auth';

const Home = loadable(() => import('pages/home'));
const DashBoard = loadable(() => import('pages/dashboard'));
const About = loadable(() => import('pages/about'));
const Login = loadable(() => import('pages/login'));
const ContactAdmin = loadable(() => import('pages/contactAdmin'));
const Members = loadable(() => import('pages/members'));
const Projects = loadable(() => import('pages/projects/lists'));
const ProjectsPayment = loadable(() => import('pages/projects/payments'));
const ProjectsSituations = loadable(() => import('pages/projects/situations'));
const ProjectDetail = loadable(() => import('pages/projects/[id]'));
const Customer = loadable(() => import('pages/projects/customers'));
const CustomerInfo = loadable(() => import('components/organisms/customerInfo'));
const SettingRole = loadable(() => import('components/organisms/SettingRole'));
const ResourcesMembers = loadable(() => import('pages/resources/members'));
const ResourcesProjects = loadable(() => import('pages/resources/projects'));
const ActualSalary = loadable(() => import('pages/management/actualsalary'));
const ResourcesSummary = loadable(() => import('pages/resources/summarys'));
const Costs = loadable(() => import('pages/management/costs/list'));
const CostDetail = loadable(() => import('pages/management/costs/detail'));
const Unauthorized = loadable(() => import('pages/403'));
const Setting = loadable(() => import('pages/settings'));

export const ROUTES = {
  // prefix
  PrefixResources: '/resources',
  PrefixProjects: '/projects',
  PrefixManagement: '/management',

  Home: '/',
  DashBoard: '/dashboard',
  About: '/about',
  Members: '/members',
  Projects: '/projects/lists',
  ProjectPayments: '/projects/payments',
  ProjectSituations: '/projects/situations',
  Customer: '/projects/customers',
  
  ProjectDetail: (id: number | string) => `/projects/lists/${id}`,
  
  CustomerInfo: '/customer-info',
  ResourcesMembers: '/resources/members',
  ResourcesProjects: '/resources/projects',
  ResourcesSummary: '/resources/summary',

  Costs: '/management/costs/lists',
  CostDetail: '/management/costs/detail',
  ActualSalary: '/management/actualsalary',

  RefreshToken: '/refresh-token',
  Setting: '/setting',
  SettingRole: (id: number | string) => `/setting/role/${id}`,
  Unauthorized: '/403',
  NotFound: '/404',

  // no auth
  Login: '/login',
  ContactAdmin: '/contact-admin',
};

const routes = [
  { exact: true, path: ROUTES.Home, component: Home, layout: Auth, isAuth: true },
  { exact: true, path: ROUTES.DashBoard, component: DashBoard, layout: Auth, isAuth: true },
  { exact: true, path: ROUTES.About, component: About, layout: Auth, isAuth: true },
  { exact: true, path: ROUTES.ResourcesMembers, component: ResourcesMembers, layout: Auth, isAuth: true },
  { exact: true, path: ROUTES.ActualSalary, component: ActualSalary, layout: Auth, isAuth: true },
  { exact: true, path: ROUTES.ResourcesProjects, component: ResourcesProjects, layout: Auth, isAuth: true },
  { exact: true, path: ROUTES.ResourcesSummary, component: ResourcesSummary, layout: Auth, isAuth: true },

  { exact: true, path: ROUTES.Costs, component: Costs, layout: Auth, isAuth: true },
  { exact: true, path: ROUTES.CostDetail, component: CostDetail, layout: Auth, isAuth: true },

  { exact: true, path: ROUTES.Members, component: Members, layout: Auth, isAuth: true },
  { exact: true, path: ROUTES.Projects, component: Projects, layout: Auth, isAuth: true },
  { exact: true, path: ROUTES.ProjectPayments, component: ProjectsPayment, layout: Auth, isAuth: true },
  { exact: true, path: ROUTES.ProjectSituations, component: ProjectsSituations, layout: Auth, isAuth: true },
  { exact: true, path: ROUTES.Customer, component: Customer, layout: Auth, isAuth: true },
  { exact: true, path: ROUTES.ProjectDetail(':id'), component: ProjectDetail, layout: Auth, isAuth: true },
  { exact: true, path: ROUTES.CustomerInfo, component: CustomerInfo, layout: Auth, isAuth: true },
  { exact: true, path: ROUTES.RefreshToken, component: RefreshToken, isAuth: true },
  { exact: true, path: ROUTES.Unauthorized, component: Unauthorized, isAuth: true },
  { exact: true, path: ROUTES.Setting, component: Setting, layout: Auth, isAuth: true },
  { exact: true, path: ROUTES.SettingRole(':id'), component: SettingRole, layout: Auth, isAuth: true },

  // no auth
  { exact: true, path: ROUTES.Login, component: Login, isAuth: false },
  { exact: true, path: ROUTES.ContactAdmin, component: ContactAdmin, isAuth: false },
];

export default routes;
